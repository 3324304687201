import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const title = "Module 2";
export const description = "";
export const image = "/chapter_headers/serverless-elements.png";
export const _frontmatter = {};
const layoutProps = {
  title,
  description,
  image,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "part-2-setting-up-the-basics"
    }}>{`Part 2: Setting up the basics`}</h1>
    <p>{`We're going to build a markdown landing page generator. You can think of it as a simple blog as well. Maybe a little silly, but it's just complex enough to exercise the stack, learn some skills, and is in fact a product I wanted to build and use.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "890px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/1382542de1d6ded172ada30f632f8169/b5a09/markdownlandingpage.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "51.12107623318386%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'205\\'%20viewBox=\\'0%200%20400%20205\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M17%2016c1%207%202%209%203%203l1-3%201%203c1%204%203%204%205-1l2-3v3c0%207%202%204%202-3%200-8-2-8-5-1l-2%205-1-4-2-5-2-1c-2%200-2%201-2%207m130-5c0%201-1%202-2%201-3%200-5%202-5%205%200%204%202%206%206%206l4-1v-6l-1-7-2%202m34%205c0%205%200%206%202%206l1-2%202-2%204-2c1-1%202-2%203%200v6h5c5%200%204-10-2-10l-3%201-2-1c-1-3-2-3-6-3h-4v7m-14-3c-3%203%200%209%204%209l2%201h-4c-3%200-3%200-2%201%201%202%206%203%208%201l1-7v-6h-4l-5%201m36%200l-1%205c0%203%201%203%204%203l3%201-4%201c-3%200-3%200-2%201%202%202%205%202%207%200%202-1%202-3%202-6%200-5-1-5-4-6l-5%201M25%2066v7l1-7v-6h356v5l1%206V59H25v7m0%207v7l1%205h47v-6l1-7H50l-25%201\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/1382542de1d6ded172ada30f632f8169/ca0a1/markdownlandingpage.webp 223w", "/static/1382542de1d6ded172ada30f632f8169/75680/markdownlandingpage.webp 445w", "/static/1382542de1d6ded172ada30f632f8169/8d1ba/markdownlandingpage.webp 890w", "/static/1382542de1d6ded172ada30f632f8169/3838e/markdownlandingpage.webp 1335w", "/static/1382542de1d6ded172ada30f632f8169/90e3c/markdownlandingpage.webp 1360w"],
                "sizes": "(max-width: 890px) 100vw, 890px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/1382542de1d6ded172ada30f632f8169/e92b6/markdownlandingpage.png 223w", "/static/1382542de1d6ded172ada30f632f8169/e66bf/markdownlandingpage.png 445w", "/static/1382542de1d6ded172ada30f632f8169/4ef49/markdownlandingpage.png 890w", "/static/1382542de1d6ded172ada30f632f8169/4e814/markdownlandingpage.png 1335w", "/static/1382542de1d6ded172ada30f632f8169/b5a09/markdownlandingpage.png 1360w"],
                "sizes": "(max-width: 890px) 100vw, 890px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/1382542de1d6ded172ada30f632f8169/4ef49/markdownlandingpage.png",
                "alt": "Markdown Landing Page dashboard",
                "title": "Markdown Landing Page dashboard",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Markdown Landing Page dashboard`}</figcaption>{`
  `}</figure></p>
    <p>{`To save time, we'll skip most of the setup and use a starter repo instead.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`git clone git@github.com:Swizec/serverless-react-workshop-starter.git
cd serverless-react-workshop-starter
`}</code></pre>
    <p>{`You get a repository with two directories:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`webapp`}</inlineCode>{` for your webapp code`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`server`}</inlineCode>{` for your serverless server code`}</li>
    </ul>
    <p>{`We use separate `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` files for the webapp and server. Install both.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`cd webapp
yarn
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`cd server
yarn
`}</code></pre>
    <p>{`Alternatively run `}<inlineCode parentName="p">{`npm install`}</inlineCode>{` in each directory. I'll be using `}<inlineCode parentName="p">{`yarn`}</inlineCode>{`, but everything should work just as well with npm.`}</p>
    <p>{`When you start the webapp with `}<inlineCode parentName="p">{`yarn start`}</inlineCode>{` or `}<inlineCode parentName="p">{`npm start`}</inlineCode>{`, you'll see a blank page.`}</p>
    <p>{`Swizec explains what's been configured for you.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      